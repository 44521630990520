// Functions first
@import "../node_modules/bootstrap/scss/functions";

// Variable overrides second
$primary: #900;
$enable-shadows: true;
$prefix: "mo-";

// Required Bootstrap imports
@import "../node_modules/bootstrap/scss/variables";
// @import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/root";

h1, h2, h3, h4, h5, h6 {
  margin: unset;
}

a {
  color: #fff;
}

br {
  margin-bottom: .8rem;
}

.App {
  width: 100%;
  position: relative;
}

.app-section {
  z-index: 2;
}

.black {
  width: 100%;
  height: 100vh;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.todd-profile-pic {
  width: calc(100vh / 4);
  height: calc(100vh / 3);
  background-image: url('/assets/images/todd-profile-pic.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
}

.logo-container {
  width: 100%;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(108, 108, 108, 1) 30%, rgba(108, 108, 108, 1) 70%, rgba(0, 0, 0, 0) 100%);
  padding: 1rem 0;

  span.logo-image {
    height: 3rem;
    width: 6rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
  }
}

.main {
  background-color: #2c2c2c;
  background-image: url(https://images.unsplash.com/photo-1502329360980-310a83b3e21d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2071&q=80);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  // height: 35rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  box-shadow: 0px 0px 30px #000;
}

.grey-underlay {
  background-color: #818181;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0
}

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #282c3447;
  z-index: 10;
}

.title-container {
  text-align: center;
  margin-bottom: 3rem;
  background-color: #00000040;
  box-shadow: 2px 2px 20px #000;
  padding: 2rem 0.5rem;
}

.main-title {
  font-size: 3.5rem;
  margin: 1rem 0;
  padding: 0 1rem;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

.main-sub-title {
  font-size: 1.5rem;
}

.content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 2rem;
  z-index: 3;
}

.video-container {
  width: 100%;
  box-shadow: 2px 2px 20px #000;
}

.highlight-video {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  aspect-ratio: 16/9;
  margin: 0;
}

.tabs-container {
  width: fit-content;
  border: 1px solid #f8f9fa;
  border-radius: 5px;
  padding: 10px;
}

.contacts {
  width: 100%;
  
  .contact {
    border-bottom: 1px solid #fff;
    padding: 1rem 0;

    ul {
      li {
        list-style: none;
      }
    }

    .contact-img {
      width: 100%;
      border-radius: 50%;
    }
  }
}

.contact-form {
  padding: 1rem 0;
}

/* Carousel CSS */

.carousel-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.carousel-wrapper {
  display: flex;
  width: 100%;
  position: relative;
}

.carousel-content-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.carousel-content {
  display: flex;
  transition: all 250ms linear;
  -ms-overflow-style: none;
  /* hide scrollbar in IE and Edge */
  scrollbar-width: none;
  /* hide scrollbar in Firefox */
}

/* hide scrollbar in webkit browser */
.carousel-content::-webkit-scrollbar,
.carousel-content::-webkit-scrollbar {
  display: none;
}

.carousel-content>* {
  width: 100%;
  flex-shrink: 0;
  flex-grow: 1;
}

.left-arrow,
.right-arrow {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background-color: white;
  border: 1px solid #ddd;
}

.left-arrow {
  left: 24px;
}

.right-arrow {
  right: 24px;
}

.carousel-content.show-2>* {
  width: 50%;
}

.carousel-content.show-3>* {
  width: calc(100% / 3);
}

.carousel-content.show-4>* {
  width: calc(100% / 4);
}

/* End Carousel CSS */

/* Hex Container */

.hex-container {
  margin-top: 1rem;
  width: 100%;
  /* height: 1000px; */
}

#hexGrid {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  margin: 0 auto;
  padding: 0;
  overflow: hidden;
  font-family: 'Raleway', sans-serif;
  font-size: 15px;
  list-style-type: none;
}

.hex {
  position: relative;
  visibility: hidden;
  outline: 1px solid transparent;
  /* fix for jagged edges in FF on hover transition */
}

.hex::after {
  content: '';
  display: block;
  padding-bottom: 86.602%;
  /* =  100 / tan(60) * 1.5 */
}

.hexIn {
  position: absolute;
  width: 96%;
  padding-bottom: 110.851%;
  /* =  width / sin(60) */
  margin: 0 2%;
  overflow: hidden;
  visibility: hidden;
  outline: 1px solid transparent;
  /* fix for jagged edges in FF on hover transition */
  -webkit-transform: rotate3d(0, 0, 1, -60deg) skewY(30deg);
  -ms-transform: rotate3d(0, 0, 1, -60deg) skewY(30deg);
  transform: rotate3d(0, 0, 1, -60deg) skewY(30deg);
}

.hexIn * {
  position: absolute;
  visibility: visible;
  outline: 1px solid transparent;
  /* fix for jagged edges in FF on hover transition */
}

.hexLink {
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: #ddd;
  color: #fff;
  overflow: hidden;
  -webkit-transform: skewY(-30deg) rotate3d(0, 0, 1, 60deg);
  -ms-transform: skewY(-30deg) rotate3d(0, 0, 1, 60deg);
  transform: skewY(-30deg) rotate3d(0, 0, 1, 60deg);
}

/*** HEX CONTENT **********************************************************************/
.hex img {
  left: -100%;
  right: -100%;
  width: auto;
  height: 100%;
  margin: 0 auto;
  -webkit-transform: rotate3d(0, 0, 0, 0deg);
  -ms-transform: rotate3d(0, 0, 0, 0deg);
  transform: rotate3d(0, 0, 0, 0deg);
  outline: 2px solid #fff;
  transition: .4s;
}

.hex h1,
.hex p {
  width: 100%;
  padding: 5%;
  box-sizing: border-box;
  background-color: rgba(0, 128, 128, 0.8);
  font-weight: 300;
  -webkit-transition: -webkit-transform .2s ease-out, opacity .3s ease-out;
  transition: transform .2s ease-out, opacity .3s ease-out;
}

.hex h1 {
  bottom: 50%;
  padding-top: 50%;
  font-size: 1.5em;
  z-index: 1;
  -webkit-transform: translate3d(0, -100%, 0);
  -ms-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
}

.hex h1::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 45%;
  width: 10%;
  text-align: center;
  border-bottom: 1px solid #fff;
}

.hex p {
  top: 50%;
  padding-bottom: 50%;
  -webkit-transform: translate3d(0, 100%, 0);
  -ms-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}


/*** HOVER EFFECT  **********************************************************************/
.hexLink:hover h1,
.hexLink:focus h1,
.hexLink:hover p,
.hexLink:focus p {
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.hexLink:hover img,
.hexLink:focus img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  transition: .4s;
  cursor: pointer;
}

/*** HEXAGON SIZING AND EVEN ROW INDENTATION *****************************************************************/
@media (min-width:1201px) {

  /* <- 5-4  hexagons per row */
  #hexGrid {
    padding-bottom: 4.4%
  }

  .hex {
    width: 20%;
    /* = 100 / 5 */
  }

  .hex:nth-child(9n+6) {
    /* first hexagon of even rows */
    margin-left: 10%;
    /* = width of .hex / 2  to indent even rows */
  }
}

@media (max-width: 1200px) and (min-width:901px) {

  /* <- 4-3  hexagons per row */
  #hexGrid {
    padding-bottom: 5.5%
  }

  .hex {
    width: 25%;
    /* = 100 / 4 */
  }

  .hex:nth-child(7n+5) {
    /* first hexagon of even rows */
    margin-left: 12.5%;
    /* = width of .hex / 2  to indent even rows */
  }
}

@media (max-width: 900px) and (min-width:601px) {

  /* <- 3-2  hexagons per row */
  #hexGrid {
    padding-bottom: 7.4%
  }

  .hex {
    width: 33.333%;
    /* = 100 / 3 */
  }

  .hex:nth-child(5n+4) {
    /* first hexagon of even rows */
    margin-left: 16.666%;
    /* = width of .hex / 2  to indent even rows */
  }
}

@media (max-width: 600px) {

  /* <- 2-1  hexagons per row */
  #hexGrid {
    padding-bottom: 11.2%
  }

  .hex {
    width: 50%;
    /* = 100 / 3 */
  }

  .hex:nth-child(3n+3) {
    /* first hexagon of even rows */
    margin-left: 25%;
    /* = width of .hex / 2  to indent even rows */
  }
}

@media (max-width: 400px) {
  #hexGrid {
    font-size: 13px;
  }
}

/* End Hex Container */

ul.media-icons {
  cursor: default;
  list-style: none;
  padding-left: 0;
  box-sizing: border-box;
}

ul.media-icons li {
  display: inline-block;
  padding: 0 0.75em 0 0;
}

ul.media-icons li a {
  border-radius: 100%;
  box-shadow: inset 0 0 0 1px #fff;
  display: inline-block;
  height: 2.25rem;
  line-height: 2.25rem;
  text-align: center;
  width: 2.25rem;
}

.icon {
  text-decoration: none;
  border-bottom: none;
  position: relative;
}

.icon>.label {
  display: none;
}

.icon:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 400;
  text-transform: none !important;
}

.about-image {
  height: 15rem;
  max-width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: .3rem;
  margin: 0 auto;
}

.experience-image {
  height: 23rem;
  max-width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: .3rem;
  margin: 0 auto;
}

.modal-content {
  background-color: rgba(27, 31, 34);
  color: #fff
}

.subtle-fields {
  background-color: #ffffffb5;
}

.dropbox-image {
  height: 200px;
}

.dropbox-slide-image {
  height: 400px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (hover: none) and (pointer: coarse) {

  .left-arrow,
  .right-arrow {
    display: none;
  }
}

@media (max-width: 991px) {
  .experience-image {
    height: 14rem;
  }
}

@media (max-width: 512px) {
  .title-container {
    background-color: unset;
    box-shadow: unset;
  }

  .experience-image {
    height: 10.5rem;
  }

  .content {
    padding: 2rem 0;
  }
}